import { useState } from "react";
import { Select } from "../../components/inputComponent";
import CaptableStats from "./captableStats/CaptableStats";
import EsopStats from "./esopStats/EsopStats";

function CompaniesStatsWrapper() {
  const [showCaptableStats, setShowCaptableStats] = useState(true);
  const [showFinancials, setShowFinancials] = useState(false);
  const [showEsopStats, setShowEsopStats] = useState(false);
  return (
    <>
      <div className="mt-4 flex justify-end">
        <Select
          className="w-auto max-w-44"
          defaultValue={"Captable Stats"}
          onChange={(e) => {
            if (e.target.value === "Captable Stats") {
              setShowCaptableStats(true);
              setShowFinancials(false);
              setShowEsopStats(false);
            } else if (e.target.value === "Financial Stats") {
              setShowCaptableStats(false);
              setShowFinancials(true);
              setShowEsopStats(false);
            } else if (e.target.value === "ESOP Stats") {
              setShowCaptableStats(false);
              setShowFinancials(false);
              setShowEsopStats(true);
            } else {
              setShowCaptableStats(false);
              setShowFinancials(false);
              setShowEsopStats(false);
            }
          }}
          options={["Captable Stats", "ESOP Stats"]}
          multiple={false}
        />
      </div>
      <div className="py-4">
        {showCaptableStats && <CaptableStats />}
        {showEsopStats && <EsopStats />}
      </div>
    </>
  );
}

export default CompaniesStatsWrapper;
