/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { toast } from "react-toastify";
import { Select } from "../../components/inputComponent";
import { useSignup } from "../../queries/auth";
import { useAuthStore } from "../../store";
import { queryClient } from "../../queries/client";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function UserProfile() {
  const [agreed, setAgreed] = useState(false);

  const [role, setRole] = useState("Intern");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const { mutate: signUpUser } = useSignup();

  const [error, setError] = useState(false);
  const user = useAuthStore();
  return (
    <>
      {(user.role === "Admin" || user.role === "SuperAdmin") && (
        <div className="isolate bg-white w-3/12 mx-4 rounded-lg">
          <div className="flex flex-row justify-between px-4 pt-4">
            <div>
              <h1 className="mt-4 text-xl text-secondary font-bold">
                Add user
              </h1>
            </div>
            <div></div>
          </div>
          <form
            action="#"
            method="POST"
            className="mx-auto max-w-xl sm:mt-4 py-4 px-4"
          >
            <div className="sm:col-span-2">
              <div className="mt-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 mt-1">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2 mt-1">
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    name="password"
                    id="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2 mt-1">
                <label
                  htmlFor="country"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Role
                </label>
                <div className="mt-1">
                  <Select
                    options={["Intern", "Legal"]}
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {error && (
              <p className="text-red-500 text-sm ">
                *All Feilds are compulsory
              </p>
            )}
            <div className="mt-10">
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    name.length > 0 &&
                    email.length > 0 &&
                    password.length > 0 &&
                    role.length > 0
                  ) {
                    setError(false);
                    const userData = {
                      name,
                      email,
                      password,
                      role,
                    };
                    signUpUser(userData, {
                      onSuccess: () => {
                        queryClient.invalidateQueries();
                        queryClient.refetchQueries();
                        toast("Added User successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: () => {
                        toast("Something went wrong", {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    });
                  } else {
                    setError(true);
                  }
                }}
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add User
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
