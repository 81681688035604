import { useMutation, useQuery } from "react-query";
import {
  getCompanyStats,
  getCompanyDetails,
  getExportExcel,
  deleteCompany,
  addUserToCompany,
  deleteCompanyRequest,
  editCompanyRequest,
  getGlobalInvestors,
  linkGlobalInvestors,
  linkCompanyInvestors,
} from "../../api/captableStats";

export function useGetCompanyStats() {
  return useQuery({
    queryKey: "getcompanystats",
    queryFn: getCompanyStats,
    enabled: false,
  });
}
export function useGetCompanyDetails(id: string) {
  return useQuery({
    queryKey: ["getcompanyDetails", id],
    queryFn: getCompanyDetails,
    enabled: true,
  });
}
export function useGetExportExcel() {
  return useMutation({
    mutationKey: "getExportExcel",
    mutationFn: getExportExcel,
  });
}
export function useDeleteCompany() {
  return useMutation({
    mutationKey: "deleteCompany",
    mutationFn: deleteCompany,
  });
}
export function usecompanyAccess() {
  return useMutation({
    mutationKey: "companyAccess",
    mutationFn: addUserToCompany,
  });
}
export function useDeleteCompanyRequest() {
  return useMutation({
    mutationKey: "deleteCompanyRequest",
    mutationFn: deleteCompanyRequest,
  });
}
export function useEditCompanyRequest() {
  return useMutation({
    mutationKey: "editCompanyRequest",
    mutationFn: editCompanyRequest,
  });
}

export function useGetGlobalInvestors() {
  return useQuery({
    queryKey: "getGlobalInvestors",
    queryFn: getGlobalInvestors,
  });
}

export function useLinkGlobalInvestors() {
  return useMutation({
    mutationKey: "linkGlobalInvestors",
    mutationFn: linkGlobalInvestors,
  });
}

export function useLinkCompanyInvestors() {
  return useMutation({
    mutationKey: "linkCompanyInvestors",
    mutationFn: linkCompanyInvestors,
  });
}
