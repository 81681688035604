import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Box, HStack, VStack } from "../../../components/utils";

export type ValidationErrorsDialogProps = {
  errors: string[];
  value: boolean;
  onSuccess: () => void;
  close: () => void;
};

const ValidationErrorsDialog = ({
  errors,
  value,
  onSuccess,
  close,
}: ValidationErrorsDialogProps) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={value} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="p-5 h-96">
                  <Box className="p-2 text-lg font-medium border-b h-1/5">
                    <HStack className="justify-between">
                      <h6 className="text-black-600">
                        Excel Sheet Validation Errors
                      </h6>
                      <span onClick={() => close()} className="cursor-pointer">
                        X
                      </span>
                    </HStack>
                  </Box>
                  <VStack className="h-4/5 w-30 overflow-y-scroll gap-3">
                    {errors.map((error) => (
                      <Box
                        key={error}
                        className="drop-shadow-box px-2 mx-2 mt-2"
                      >
                        <p className="text-red-600">{error}</p>
                      </Box>
                    ))}
                  </VStack>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ValidationErrorsDialog;
