import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  ClientSideRowModelModule,
  ColDef,
  GetDataPath,
  IGroupCellRendererParams,
  RowGroupingModule,
} from "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import QuickSearch from "../../components/QuickSearch";
import { HStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import {
  GlobalAccount,
  UpdateGlobalInvestorStateInput,
} from "../../types/globalInvestorsModel";
import {
  ActionRender,
  EmailRender,
  InvestorNameRender,
  StatusRender,
} from "./GlobalInvestorAGComponent";

import {
  useGetGlobalInvestors,
  useLinkGlobalInvestors,
} from "../../queries/companyStats/captableStats";
import { classNames } from "../../utils/string";

const GlobalInvestorTable: React.FC = () => {
  const user = useAuthStore();
  const [selectedData, setSelectedData] = useState<any[]>();
  const [showButtons, setShowButtons] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const [groupDefaultExpanded, setGroupDefaultExpanded] = useState(0);
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);

  const { data, isSuccess, refetch } = useGetGlobalInvestors();
  const { mutate: LinkInvestor } = useLinkGlobalInvestors();

  let globalInvestors: GlobalAccount[] = [];

  if (isSuccess) {
    globalInvestors = data as GlobalAccount[];
  }

  function onSelectionChanged() {
    const selectedNodes = gridApi.current.api.getSelectedNodes();
    const selectedRows = selectedNodes.map((node: any) => node.data);
    setSelectedData(selectedRows);
  }

  const handleApprove = () => {
    const inputData: UpdateGlobalInvestorStateInput[] = [];

    if (selectedData && selectedData.length > 0)
      selectedData?.forEach((obj: any) => {
        inputData.push({
          globalAccountId: obj.globalAccountId,
          globalInvestorId: obj.globalInvestorId,
          status: "approved",
          isAccount: false,
          isInvestor: true,
        });
      });

    LinkInvestor(inputData, {
      onSuccess: (res) => {
        toast(`Successfully `, {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err?.response?.data?.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  const columnDefs: ColDef[] = useMemo(
    () => [
      // {
      //   headerName: "Investor name",
      //   field: "investorName",
      //   filter: "agTextColumnFilter",
      //   // headerCheckboxSelection: true,
      //   // checkboxSelection: true,
      //   // headerCheckboxSelectionFilteredOnly: true,
      //   // rowMultiSelectWithClick: true,
      //   sortable: true,
      //   // cellRenderer: InvestorNameRender,
      // },
      {
        headerName: "Company Name",
        field: "companyName",
        filter: "agTextColumnFilter",
        sortable: true,
      },
      {
        headerName: "Company Investor Name",
        field: "companyInvestor",
        filter: "agTextColumnFilter",
        sortable: true,
      },
      {
        headerName: "Account Name",
        field: "accountName",
        filter: "agTextColumnFilter",
        sortable: true,
      },
      {
        headerName: "Admin Email",
        field: "email",
        filter: "agTextColumnFilter",
        sortable: true,
        cellRenderer: EmailRender,
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        sortable: false,
        valueGetter: (params) => params.data.status,
        valueFormatter: (params) => params.data.status,
        cellRenderer: StatusRender,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 70,
        maxWidth: 70,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRenderer: ActionRender,
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      flex: 1,
      wrapHeaderText: true,
      suppressColumnsToolPanel: true,
      menuTabs: ["filterMenuTab"],
      columnChooserParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      filter: true,
      resizable: true,
    }),
    [],
  );

  const rowData = useMemo(() => {
    return globalInvestors?.flatMap((account, accountIndex) => {
      return account.investors.flatMap((investor, investorIndex) => {
        const investorName = investor.name;
        const email = account.email;
        const accountName = account.name;
        const status = investor.status;
        const globalAccountId = account.globalAccountId;
        const globalInvestorId = investor.globalInvestorId;

        const baseRowId = `account-${accountIndex}-investor-${investorIndex}`;
        const finalData: any[] = [
          // {
          //   orgHierarchy: [investorName],
          //   globalInvestorId,
          // },
          {
            id: `${baseRowId}`, // unique id for internal identification
            orgHierarchy: [`${investorName}-${baseRowId}`],
            investorName,
            email,
            accountName,
            status,
            globalAccountId,
            globalInvestorId,
          },
        ];

        investor.linkedCompanies?.forEach((company, companyIndex) => {
          finalData.push({
            id: `${baseRowId}-company-${companyIndex}`, // unique id for internal identification
            orgHierarchy: [
              `${investorName}-${baseRowId}`,
              company.cInvestorName,
            ],
            investorName,
            accountName,
            company,
            globalAccountId,
            globalInvestorId,
            companyName: company.companyName,
            status: company.status,
            companyId: company.companyId,
            companyInvestorId: company.cInvestorId,
            companyInvestor: company.cInvestorName,
          });
        });

        return finalData;
      });
    });
  }, [globalInvestors, groupDefaultExpanded, open]);

  const CustomHeaderComponent = () => (
    <div>
      <HStack className="cursor-pointer" onClick={() => handleGroupToggle()}>
        <div className="pt-1">
          <ChevronDownIcon
            className={classNames(
              open ? "rotate-0" : "-rotate-90",
              "h-3 w-5 transform text-slate-500",
            )}
            strokeWidth={2.5}
          />
        </div>
        <div>
          <span className="px-4">Global Investor Name</span>
          {/* <p className="text-xs font-medium text-gray-400 px-9">
            {"Global Investor ID"}
          </p> */}
        </div>
      </HStack>
    </div>
  );

  const handleGroupToggle = () => {
    if (open) {
      setOpen(!open);
      setGroupDefaultExpanded(0);
    } else {
      setOpen(!open);
      setGroupDefaultExpanded(-1);
    }
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 300,
      menuTabs: [],
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: InvestorNameRender,
        suppressCount: true,
        // checkbox: true,  // Enable this to show checkboxes
      } as IGroupCellRendererParams,
      sortable: true,
      valueGetter: (params) =>
        params.data.orgHierarchy[params.data.orgHierarchy.length - 1].split(
          "-",
        )[0],
      pinned: true,

      headerComponent: CustomHeaderComponent,
    }),
    [globalInvestors, groupDefaultExpanded, open],
  );

  const getDataPath = useMemo<GetDataPath>(
    () => (data: { orgHierarchy: any }) => data.orgHierarchy,
    [],
  );

  return (
    <div>
      <div className="flex flex-row justify-between ">
        <div>
          <h1 className="mt-4 text-xl font-bold text-secondary">
            Global Investor List
          </h1>
        </div>

        <div className="flex flex-row items-center justify-end py-4">
          <HStack className="flex justify-center">
            <QuickSearch
              placeholder={`Search`}
              onChange={(e) => {
                gridApi.current.api.setGridOption(
                  "quickFilterText",
                  e.target.value,
                );
              }}
            />
          </HStack>
        </div>
      </div>
      {/* {selectedData && selectedData.length > 0 && (
        <div className="flex flex-row items-center justify-end pb-2">
          <HStack className="flex justify-center">
            <PrimaryCTAButton
              className="mx-2 mb-1 bg-success hover:bg-success hover:border-white"
              onClick={() => {
                handleApprove();
              }}
            >
              Approve
            </PrimaryCTAButton>

            <PrimaryCTAButton
              className="mb-1 bg-reject hover:bg-reject hover:border-white"
              onClick={() => {}}
            >
              Reject
            </PrimaryCTAButton>
          </HStack>
        </div>
      )} */}
      <div
        className="pb-4 ag-theme-alpine"
        style={{ height: 550, width: "100%" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule, RowGroupingModule]}
          columnDefs={columnDefs}
          rowData={rowData}
          ref={gridRef}
          onGridReady={(params) => {
            gridApi.current = params;
          }}
          treeData={true}
          animateRows={true}
          alwaysMultiSort
          autoGroupColumnDef={autoGroupColumnDef}
          groupDefaultExpanded={groupDefaultExpanded}
          suppressRowTransform={true}
          // rowSelection={"multiple"}
          groupSelectsChildren={true}
          getDataPath={getDataPath}
          onSelectionChanged={onSelectionChanged}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default GlobalInvestorTable;
