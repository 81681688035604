import { LoginDetailsInterface, LoginResponseInterface } from "../types/common";
import captableApi from "./captableApi";
import onBoardingApi from "./onBoardingApi";

export async function login(
  signInDetails: LoginDetailsInterface,
): Promise<{ message: string; data: LoginResponseInterface }> {
  return onBoardingApi
    .post(`auth/login`, signInDetails)
    .then((res) => res.data);
}

export interface SignInDetailsModel {
  email: string;
  password: string;
}

export interface SignResponseModel {
  companyId: string;
  setPassword: string;
  token: string;
}

export async function captableSignIn(
  signInDetails: SignInDetailsModel,
): Promise<{ message: string; data: SignResponseModel }> {
  return captableApi
    .post(`v1/auth/login`, signInDetails)
    .then((res) => res.data);
}

export async function signUp(
  data: any,
): Promise<{ message: string; data: any }> {
  return onBoardingApi.post(`auth/signUp`, data).then((res) => res.data);
}
