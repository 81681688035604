import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../store/authStore";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../../components/CTAButtonComponents";
import { Select } from "../../../components/inputComponent";
import { TableData } from "../../../utils/Utility";
import { SwitchButton } from "../../../components/SwitchButton";
import { usecompanyAccess } from "../../../queries/companyStats/captableStats";

type Props = {
  isPopupOpen: boolean;
  onClose: () => void;
  userList: any;
};
function UserAccessPopup({ isPopupOpen, onClose, userList }: Props) {
  const user = useAuthStore();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("OTHERS");
  const [roleName, setRoleName] = useState("Admin");
  const [error, setError] = useState(false);
  const [mail, setMail] = useState(false);
  const { mutate: addAccess } = usecompanyAccess();
  const list: TableData[] = userList.companyAccessData;
  function handleSubmit() {
    if (
      fullName.length > 0 &&
      email.length > 0 &&
      designation.length > 0 &&
      roleName.length > 0
    ) {
      let roleGrants;
      const companyId = userList.id;
      if (roleName === "Admin") {
        roleGrants = [
          { role: "Admin", action: "*", resource: "*", attributes: ["*"] },
        ];
      } else if (roleName === "Admin Viewer") {
        roleGrants = [
          {
            role: "Admin Viewer",
            action: "read",
            resource: "*",
            attributes: ["*"],
          },
        ];
      }
      setError(false);
      const userObject = {
        fullName,
        email,
        designation,
        roleName,
        roleGrants,
        companyId,
        silentAccess: mail,
      };
      addAccess(userObject, {
        onSuccess: (res) => {
          const successMsg = mail ? "Access Granted" : "Email Sent Succesfully";
          toast(successMsg, {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      setError(true);
    }
  }
  return (
    <Transition.Root show={isPopupOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div
                  className={`flex p-2 ${user.role === "Admin" || user.role === "SuperAdmin" ? "justify-between" : "justify-center"}`}
                >
                  {(user.role === "Admin" || user.role === "SuperAdmin") && (
                    <div className="sm:w-72">
                      <h1 className="font-bold text-lg">Add user to company</h1>
                      <form
                        className="mx-auto max-w-xl py-4 px-4"
                        action="#"
                        method="POST"
                      >
                        <div>
                          <label htmlFor="fullName" className="block text-sm">
                            FullName :
                          </label>
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            onChange={(e) => {
                              setFullName(e.target.value);
                            }}
                            className="block w-full rounded-md border-0 text-grey-900 placeholder:text-gray-400 ring-gray-300 ring-1 ring-inset"
                          />
                        </div>
                        <div className="py-2">
                          <label htmlFor="email" className="block text-sm">
                            Email :
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            onChange={(e) => {
                              const emailId = e.target.value.trim();
                              setEmail(emailId);
                            }}
                            className="block w-full rounded-md border-0 text-grey-900 placeholder:text-gray-400 ring-gray-300 ring-1 ring-inset"
                          />
                        </div>
                        <div>
                          <label htmlFor="roleName" className="block text-sm">
                            Role Name :
                          </label>
                          <Select
                            onChange={(e) => {
                              setRoleName(e.target.value);
                            }}
                            value={roleName}
                            options={["Admin", "Admin Viewer"]}
                          />
                        </div>
                        <div className="py-2">
                          <label
                            htmlFor="designation"
                            className="block text-sm"
                          >
                            Designation :
                          </label>
                          <Select
                            onChange={(e) => {
                              setDesignation(e.target.value);
                            }}
                            value={designation}
                            options={[
                              { value: "FOUNDER", label: "Founder" },
                              { value: "CFO", label: "CFO" },
                              { value: "DIRECTOR", label: "Director" },
                              { value: "ATTORNEY", label: "Attorney" },
                              {
                                value: "COMPANY_SECRETARY",
                                label: "Company Secretary",
                              },
                              {
                                value: "CHARTED_ACCOUNTANT",
                                label: "Chartered Accountant",
                              },
                              {
                                value: "COMPLIANCE_OFFICER",
                                label: "Compliance Officer",
                              },
                              { value: "CONSULTANT", label: "Consultant" },
                              { value: "OTHERS", label: "Others" },
                            ]}
                            valueGetter={(option) => option.value}
                            textGetter={(option) => option.label}
                          />
                        </div>
                        <div className="flex flex-auto">
                          <div className="p-2">Silent Access :</div>
                          <SwitchButton
                            label=""
                            value={mail}
                            onClick={() => {
                              setMail(!mail);
                            }}
                          ></SwitchButton>
                        </div>
                        {error && (
                          <p className="text-red-500 text-sm ">
                            *All Feilds are compulsory
                          </p>
                        )}
                        <div className="flex justify-between py-2">
                          <SecondaryCTAButton onClick={onClose}>
                            Cancel
                          </SecondaryCTAButton>
                          <PrimaryCTAButton
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubmit();
                            }}
                          >
                            Add User
                          </PrimaryCTAButton>
                        </div>
                      </form>
                    </div>
                  )}
                  <div>
                    <h1 className="font-bold text-lg">
                      List of Users with Access
                    </h1>
                    <div className="overflow-y-scroll h-80">
                      <ul role="list" className="divide-y divide-gray-100">
                        {list?.map((user, index: number) => (
                          <li key={index} className="py-2">
                            <h1>{user.email}</h1>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UserAccessPopup;
