import jwtDecode from "jwt-decode";
import { HTMLAttributes, ReactElement } from "react";
import { useAuthStore } from "../store";

export function HStack(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function clearCredentials() {
  const credentialKeys = [
    "accesstoken",
    "hissaUrl",
    "companyId",
    "x-usr-token",
  ];
  credentialKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    xUserToken: string;
    exp: number;
  };
  return decodedToken;
}

export function decodeCaptableAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string;
    fullName: string;
    emailId: string;
    companyId: string;
    role: string;
    employeeId: string;
    type: string;
  };
  return decodedToken;
}

export function checkTokenValidity() {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  const token = localStorage.getItem("accesstoken");
  if (!token) return true;
  const decodedToken = decodeAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}

export function revalidateAuth() {
  if (!checkTokenValidity()) {
    useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
  }
}

export function isTokenValid(token: string | undefined) {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  if (!token) return false;
  const decodedToken = decodeAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}
