import { Icon } from "@iconify/react";
import React, { ButtonHTMLAttributes } from "react";
import { HStack } from "./utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

type SecondaryCTAButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
} & ButtonProps;

export enum ButtonSize {
  SMALL = "px-2 py-1.5 text-xs1 leading-[18px]",
  MEDIUM = "px-3 py-2.5 text-xs2 leading-4",
}

export function SecondaryCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  disabled,
  ...rest
}: SecondaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex bg-transparent items-center ${buttonSize} rounded-md border border-primary  whitespace-nowrap font-semibold text-primary drop-shadow-box hover:bg-primary/20 focus:outline-none ${className}${disabled ? "cursor-not-allowed" : "cursor-pointer"}`;

  return <button {...rest} className={buttonClassName} type={type} />;
}

type PrimaryCTAButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
} & ButtonProps;

export function PrimaryCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  disabled,
  ...rest
}: PrimaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex items-center ${buttonSize} rounded-md border border-transparent bg-primary hover:bg-primaryHover  whitespace-nowrap font-semibold text-white drop-shadow-box hover:bg-primary/70 focus:outline-none ${className} ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`;

  return <button {...rest} className={buttonClassName} type={type} />;
}

export function TertiaryCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  ...rest
}: PrimaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex items-center border border-[#F9F9F9] cursor-pointer ${buttonSize} rounded-md border border-transparent bg-tertiaryBg whitespace-nowrap font-semibold text-tertiaryText drop-shadow-box hover:bg-tertiaryBgHover focus:outline-none ${className}`;

  return <button {...rest} className={buttonClassName} type={type} />;
}

export enum IconSize {
  S = 12,
  M = 18,
  L = 24,
}

export enum IconPlacement {
  LEFT,
  RIGHT,
}

export enum IconButtonType {
  FILLED = "bg-orange-501 text-white shadow-sm font-semibold",
  OUTLINED = "font-semibold",
  NONE = "font-medium",
}

type IconCTAButtonProps = {
  value?: string;
  buttonSize?: ButtonSize;
  selected?: boolean;
  iconName?: string;
  buttonType?: IconButtonType;
  className?: string;
  iconClassName?: string;
  iconSize?: IconSize;
  iconPlacement?: IconPlacement;
} & ButtonProps;

export function IconCTAButton(props: IconCTAButtonProps) {
  const {
    value,
    selected = false,
    iconName,
    buttonType = IconButtonType.OUTLINED,
    buttonSize = ButtonSize.MEDIUM,

    className,
    iconClassName,
    iconSize = IconSize.M,
    iconPlacement = IconPlacement.LEFT,
    ...rest
  } = props;
  const placementClass =
    iconPlacement === IconPlacement.RIGHT ? "flex-row-reverse" : "";
  const selectedStyle = selected
    ? "text-white bg-secondary"
    : "text-secondary  bg-transparent";
  const buttonClassName = `${buttonType} inline-flex border border-borderColor items-center gap-x-1 rounded-sm ${buttonSize} font-medium ${className} ${placementClass} ${
    buttonType === IconButtonType.OUTLINED ? selectedStyle : "border-none"
  }`;
  return (
    <button {...rest} className={buttonClassName} type={"button"}>
      {iconName && (
        <Icon
          aria-hidden="true"
          icon={iconName}
          className={iconClassName}
          height={iconSize}
          width={iconSize}
        />
      )}
      {value}
    </button>
  );
}

type LoaderButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
} & ButtonProps;

export function LoaderCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  ...rest
}: LoaderButtonProps): React.ReactElement {
  const _className = `inline-flex items-center cursor-not-allowed ${buttonSize} rounded-sm border border-transparent bg-primary px-3 py-2.5 text-sm font-medium leading-4 text-white drop-shadow-box hover:bg-primary/70 ${className}`;

  return (
    <button {...rest} className={_className} type={type} disabled>
      <HStack>
        <p className="indicator-progress" style={{ display: "block" }}>
          Please wait
        </p>
        <Icon className="ml-2 animate-spin" icon="lucide:loader-2" width={18} />
      </HStack>
    </button>
  );
}
