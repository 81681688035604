import React from "react";
import MyAgGridTable from "./table";

const DashBoard = () => {
  return (
    <div className="h-[calc(100vh-10vh)]">
      <MyAgGridTable />
    </div>
  );
};

export default DashBoard;
