import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../../components/CTAButtonComponents";
import {
  useDeleteCompany,
  useEditCompanyRequest,
} from "../../../queries/companyStats/captableStats";
import { SwitchButton } from "../../../components/SwitchButton";

type Props = {
  showPopup: boolean;
  onClose: () => void;
  gridRef: any;
  refetchTableData: () => void;
};
export const EditCompanyPopup = ({ showPopup, onClose, gridRef }: Props) => {
  const { mutate: editRequest } = useEditCompanyRequest();
  const [isPrivate, setIsprivate] = useState<boolean>(false);
  const [isClient, setIsClient] = useState<boolean>(false);
  const handleEditRequest = () => {
    const id = gridRef.current?.api.getSelectedRows()[0].id;
    const data = { private: !!isPrivate, client: !!isClient, id };
    editRequest(data, {
      onSuccess: (res: any) => {
        if (res !== undefined) {
          toast("Edit Succesfully", {
            type: "success",
            autoClose: 2000,
          });
        }
        onClose();
      },
      onError: (error: any) => {
        toast(error.response.data.errors, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  useEffect(() => {
    setIsprivate(
      gridRef.current?.api.getSelectedRows()[0]?.isPrivate === "Yes",
    );
    setIsClient(gridRef.current?.api.getSelectedRows()[0]?.isClient === "Yes");
  }, []);
  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Edit Companies Details
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <label className="text-sm text-gray-500 mt-2">
                            isPrivate :
                          </label>
                          <SwitchButton
                            label=""
                            value={isPrivate}
                            onClick={() => {
                              setIsprivate(!isPrivate);
                            }}
                          ></SwitchButton>
                        </div>
                        <div className="flex justify-between">
                          <label className="text-sm text-gray-500 mt-2">
                            isClient :
                          </label>
                          <SwitchButton
                            label=""
                            value={isClient}
                            onClick={() => {
                              setIsClient(!isClient);
                            }}
                          ></SwitchButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <PrimaryCTAButton
                    className={`sm:ml-3 cursor-pointer`}
                    onClick={handleEditRequest}
                  >
                    Edit Details
                  </PrimaryCTAButton>
                  <SecondaryCTAButton onClick={() => onClose()}>
                    Cancel
                  </SecondaryCTAButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const DeleteCompanyPopup = ({
  showPopup,
  onClose,
  gridRef,
  refetchTableData,
}: Props) => {
  const [otp, setOtp] = useState("");
  const [deleteBtn, setBeleteBtn] = useState(true);
  const { mutate: deleteCompany } = useDeleteCompany();
  const handleDelete = async () => {
    const id = gridRef.current.api.getSelectedRows()[0].id;
    const data = {
      id,
      otp,
    };
    deleteCompany(data, {
      onSuccess: () => {
        refetchTableData();
        onClose();
        toast("Company Deleted Succesfully", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (error: any) => {
        toast(error.response.data.errors, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Companies Permanently
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete the comapnies? All the
                          company data will be permanently removed. This action
                          cannot be undone.
                        </p>
                        <label className="text-sm text-gray-500">OTP:</label>
                        <input
                          type="text"
                          name="OTP"
                          className="block w-md rounded-md border-0 text-grey-900 placeholder:text-gray-400 ring-gray-300 ring-1 ring-inset"
                          onChange={(e) => {
                            const value = e.target.value.toString();
                            if (value.length === 6) {
                              setBeleteBtn(false);
                            } else {
                              setBeleteBtn(true);
                            }
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <PrimaryCTAButton
                    className={`sm:ml-3 ${deleteBtn ? "cursor-not-allowed" : "cursor-pointer"}`}
                    disabled={deleteBtn}
                    onClick={handleDelete}
                  >
                    Delete Permanently
                  </PrimaryCTAButton>
                  <SecondaryCTAButton onClick={() => onClose()}>
                    Cancel
                  </SecondaryCTAButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
