import { format } from "date-fns";

export function formatDisplayDate(dateStr: string | Date | undefined) {
  if (checkVoid(dateStr)) return "-";
  const dateFormat = "dd MMM yyyy";
  if (!dateStr) {
    dateStr = "-";
  }
  if (typeof dateStr === "string") {
    return format(new Date(dateStr), dateFormat);
  } else {
    return format(dateStr, dateFormat);
  }
}

export const checkVoid = (data: any) => data === undefined || data === null;
