import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { useAuthStore } from "../store";
import { clearCredentials, revalidateAuth } from "../components/utils";

const postOnBoardingApi = axios.create({
  baseURL:
    process.env.REACT_APP_ONBOARDING_URL ||
    "https://onboarding-api.hissa.com/v1/",
});

postOnBoardingApi.defaults.headers.post["Content-Type"] = "multipart/form-data";

postOnBoardingApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
  }
  if (config.url === "exit") {
    delete (config.headers as AxiosRequestHeaders).AccessToken;
  }
  return config;
});

postOnBoardingApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
    }
    return Promise.reject(error);
  },
);

export default postOnBoardingApi;
