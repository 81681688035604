import * as AgGrid from "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "ag-grid-enterprise/styles/ag-theme-material.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { queryClient } from "./queries/client";
import Router from "./routes";
import { useAuthStore } from "./store";

const agGridLicenseKey = process.env.REACT_APP_AG_LICENSE_KEY || "";
AgGrid.LicenseManager.setLicenseKey(agGridLicenseKey);

function App() {
  useAuthStore();
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
      <ToastContainer position="bottom-right" autoClose={2000} />
    </QueryClientProvider>
  );
}

export default App;
