import { QueryFunctionContext } from "react-query";
import onBoardingApi from "./onBoardingApi";

export async function getCompanyStats(
  context?: QueryFunctionContext,
): Promise<any> {
  return onBoardingApi
    .get(`/captableCompanies/captablestats`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function getCompanyDetails(
  context?: QueryFunctionContext,
): Promise<any> {
  const id = context?.queryKey[1];
  return onBoardingApi
    .get(`/captableCompanies/companyDetails`, {
      params: {
        id,
      },
    })
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function getExportExcel(data: any): Promise<any> {
  return onBoardingApi
    .post(`/captableCompanies/exportToExcel`, {
      data: {
        data,
      },
    })
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function deleteCompany(data: any): Promise<any> {
  const companyId = data.id;
  const otp = data.otp;
  return onBoardingApi
    .delete(`/captableCompanies/permanentDelete`, {
      data: {
        companyId,
        otp,
      },
    })
    .then((res: any) => res.response);
}
export async function addUserToCompany(data: any): Promise<any> {
  return onBoardingApi
    .post(`/captableCompanies/userAccess`, {
      ...data,
    })
    .then((res: any) => res.response);
}
export async function deleteCompanyRequest(companyId: string): Promise<any> {
  return onBoardingApi
    .post(`/captableCompanies/deleteCompanyRequest`, { companyId })
    .then((res: any) => res.response);
}
export async function editCompanyRequest(data: any): Promise<any> {
  return onBoardingApi
    .post(`/captableCompanies/editCompanyDetails`, { data })
    .then((res: any) => res.data);
}

export async function getGlobalInvestors(): Promise<any> {
  return onBoardingApi
    .get(`/captableCompanies/getGlobalInvestors`)
    .then((res: any) => res.data.data)
    .catch((error: any) => error.data);
}

export async function linkGlobalInvestors(data: any): Promise<any> {
  return onBoardingApi.post(`/captableCompanies/addGlobalInvestors`, { data });
}

export async function linkCompanyInvestors(data: any): Promise<any> {
  return onBoardingApi.post(`/captableCompanies/addCompanyInvestors`, { data });
}
