import axios from "axios";

export const getCaptableToken = async () => {
  const emailId = process.env.REACT_APP_HISSA_CAPTABLE_USERNAME || "";
  const password = process.env.REACT_APP_HISSA_CAPTABLE_PASSWORD || "";
  const payload = JSON.stringify({
    email: emailId,
    password,
  });
  const headersForLogin = {
    "Content-Type": "application/json",
  };
  const onboardingUrl =
    process.env.REACT_APP_CAPTABLE_URL || "https://captable-api.hissa.com/v1/";
  const response = await axios({
    method: "post",
    url: `${onboardingUrl}auth/login`,
    headers: headersForLogin,
    data: payload,
  });

  if (response.status > 200) throw new Error("Cannot Generate Token");
  const accessToken = response.data.data.token;
  if (!accessToken) throw new Error("Cannot Generate Token");
  return accessToken;
};
