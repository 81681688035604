import React from "react";

export const AgNameComponent = (data: any) => {
  return (
    <div className="cursor-pointer">
      <p className="text-sm">{data.data.companyName}</p>
      <p className="text-xs text-gray-500 tracking-tighter">
        cin: {data.data.cin}
      </p>
    </div>
  );
};
