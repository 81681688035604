import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef, RowSelectionOptions } from "ag-grid-enterprise";
import { toast } from "react-toastify";
import { EsopDetails } from "./EsopStats";
import { useAdminUserDetails } from "../../../queries/companyStats/esopStats";
import { PrimaryCTAButton } from "../../../components/CTAButtonComponents";
import { WarningDialog } from "./EsopAgComponents";

type UserDetails = {
  id: string;
  userId: bigint;
  email: string;
  role: UserRole;
  name: string;
  isCompanySpecificRole: boolean;
  userSince: Date;
  designation: string;
};
type UserRole = {
  name: string;
  id: string;
};
export type UserRowDetails = {
  userId: string;
  full_name: string;
  email: string;
  role: string;
};
function CompanyDetailsPage() {
  const location = useLocation();
  const gridApi = useRef<AgGridReact>(null);
  const companyDetails: EsopDetails = location?.state;
  const [adminDetails, setAdminDetails] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);
  const [userDetails, setUserDetails] = useState<UserRowDetails[]>([]);
  const { mutate: mutateAdminDetails } = useAdminUserDetails();
  useEffect(() => {
    if (companyDetails?.companyId) {
      mutateAdminDetails(companyDetails.companyId, {
        onSuccess: (data: any) => {
          setAdminDetails(data);
        },
        onError: (error: any) => {
          toast(error.data.message, { type: "error", autoClose: 2000 });
        },
      });
    }
  }, [companyDetails]);
  const handleDelete = () => {
    setDeleteUser(true);
    setUserDetails(gridApi.current?.api.getSelectedRows() || []);
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );
  const rowSelection = useMemo<RowSelectionOptions>(() => {
    return {
      mode: "singleRow",
      enableClickSelection: true,
    };
  }, []);
  const rowsData = useMemo(
    () =>
      adminDetails?.map((admin: UserDetails) => ({
        userId: admin.id,
        full_name: admin.name,
        email: admin.email,
        role: admin.role.name.replaceAll("_", " ").toLowerCase(),
      })),
    [adminDetails],
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "User Name",
      field: "full_name",
      // minWidth: 300,
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
    {
      headerName: "Email",
      field: "email",
      // minWidth: 300,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "User Role",
      field: "role",
      // minWidth: 300,
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
  ];
  return (
    <>
      <div className="p-5 rounded-lg shadow-custom">
        <div className="text-2xl flex">
          Company Name :
          <div className="font-bold ms-2">{companyDetails.companyName}</div>
        </div>
        <div className="text-xl flex">
          CIN :<div className="font-bold ms-2"> {companyDetails.cin}</div>
        </div>
        <div className="text-xl flex">
          DBA :<div className="font-bold ms-2">{companyDetails.dbaName}</div>
        </div>
      </div>
      <div className="flex justify-between py-5 gap-4">
        <div className="px-5 py-2 shadow-custom rounded-xl text-[16px] w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-bold">Plan Details</div>
          <div>
            <div className="flex justify-between">
              Total Plan Pool :
              <div className="">{companyDetails.totalActivePlanPoolSize}</div>
            </div>
            <div className="flex justify-between">
              Total Plans :{" "}
              <div className="">{companyDetails.totalNumberOfPlans}</div>
            </div>
          </div>
        </div>
        <div className="px-5 py-2 shadow-custom rounded-xl text-[16px] w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-bold">Employee Details</div>
          <div>
            <div className="flex justify-between">
              Total Employees :
              <div className="">{companyDetails.totalNumberOfEmployees}</div>
            </div>
            <div className="flex justify-between">
              Employed :
              <div className="">
                {companyDetails.totalNumberOfEmployees -
                  companyDetails.totalNumberOfResignedEmployees}
              </div>
            </div>
            <div className="flex justify-between">
              Resigned :{" "}
              <div className="">
                {companyDetails.totalNumberOfResignedEmployees}
              </div>
            </div>
            <div className="flex justify-between">
              Access Given :{" "}
              <div className="">
                {companyDetails.numberOfEmployeesGivenAccess}
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 py-2 rounded-lg shadow-custom w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-bold">Pool Details</div>
          <div className="text-[16px] space-y-2">
            <div className="flex justify-between ">
              Total Granted :<div>{companyDetails.totalGranted}</div>
            </div>
            <div className="flex justify-between ">
              Total Vested :<div>{companyDetails.totalVested}</div>
            </div>
            <div className="flex justify-between ">
              Total Surrendered :<div>{companyDetails.totalSurrendered}</div>
            </div>
            <div className="flex justify-between ">
              Total Stock Exercise :
              <div>{companyDetails.totalStockExercise}</div>
            </div>
            <div className="flex justify-between ">
              Total Lapsed :<div>{companyDetails.totalLapsed}</div>
            </div>
            <div className="flex justify-between ">
              Total Cashed out :<div>{companyDetails.totalCashedout}</div>
            </div>
            <div className="flex justify-between ">
              Total Sold :<div>{companyDetails.totalSold}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 ag-theme-quartz h-[calc(100vh-50vh)]">
        <div className="flex pb-4 justify-between">
          <div className="text-2xl font-bold">Admin Details</div>
          <PrimaryCTAButton
            disabled={disableDeleteBtn}
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </PrimaryCTAButton>
        </div>
        <AgGridReact
          ref={gridApi}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowsData}
          suppressRowTransform={true}
          pagination={true}
          animateRows={true}
          rowSelection={rowSelection}
          onRowSelected={(e: any) => {
            const a =
              e.api.getSelectedRows().length > 0
                ? setDisableDeleteBtn(false)
                : setDisableDeleteBtn(true);
          }}
        />
      </div>
      {deleteUser && (
        <WarningDialog
          open={deleteUser}
          companyId={companyDetails.companyId}
          userDetails={userDetails}
          onClose={() => {
            setDeleteUser(!deleteUser);
            if (companyDetails?.companyId) {
              mutateAdminDetails(companyDetails.companyId, {
                onSuccess: (data: any) => {
                  setAdminDetails(data);
                  setDisableDeleteBtn(true);
                },
                onError: (error: any) => {
                  toast(error.data.message, { type: "error", autoClose: 2000 });
                },
              });
            }
          }}
        />
      )}
    </>
  );
}

export default CompanyDetailsPage;
