import { useMutation } from "react-query";
import { login, signUp } from "../api/login";

export function useLogin() {
  return useMutation({
    mutationKey: "login",
    mutationFn: login,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useSignup() {
  return useMutation({
    mutationKey: "signUp",
    mutationFn: signUp,
    onSuccess: () => {},
    onError: () => {},
  });
}
