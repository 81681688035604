import { toast } from "react-toastify";

export function downloadS3File(file: string, fileName = "") {
  const anchor = document.createElement("a");
  anchor.href = file;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.click();
}
export async function convertBase64ToBlob(base64: string, type: string) {
  return fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
}
export function downloadBlobObject(blob: Blob, fileName: string) {
  const anchor = document.createElement("a");
  const blobURL = URL.createObjectURL(blob);
  anchor.href = blobURL;
  anchor.download = fileName;
  anchor.click();
}
export const downloadExcel = async (
  base64: string | undefined,
  fileName: string,
) => {
  if (base64) {
    const blob = await convertBase64ToBlob(
      base64,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );
    downloadBlobObject(blob, fileName);
    toast("Successfully Downloaded", {
      type: "success",
      autoClose: 2000,
    });
  } else {
    toast("Something Went Wrong", { type: "error", autoClose: 2000 });
  }
};

export const convertToBase64 = (file: File, toResolveasDataUrl = false) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(
        toResolveasDataUrl
          ? fileReader?.result?.toString()
          : fileReader?.result
              ?.toString()
              .replace("data:", "")
              .replace(/^.+,/, ""),
      );
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
