import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../../components/CTAButtonComponents";
import { useDeleteUser } from "../../../queries/companyStats/esopStats";
import { UserRowDetails } from "./CompanyDetailsPage";

type Props = {
  open: boolean;
  onClose: () => void;
  companyId: string;
  userDetails: UserRowDetails[];
};
export const CompanyDetailPage = (data: any) => {
  const navigate = useNavigate();
  const companyName = data.data.companyName;
  return (
    <div
      className="hover:text-primary"
      onClick={() => {
        navigate("/companyDetails", { state: data.data });
      }}
    >
      {companyName}
    </div>
  );
};
export const WarningDialog = ({
  open,
  onClose,
  companyId,
  userDetails,
}: Props) => {
  const { mutate: deleteUser } = useDeleteUser();
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const emailId = userDetails[0]?.email;
  const handleDelete = () => {
    if (email !== emailId) {
      setIsError(true);
    } else {
      setIsError(false);
      const data = { companyId, userDetails };
      deleteUser(data, {
        onSuccess: (data: any) => {
          onClose();
          if (data.count === 1) {
            toast("User Deleted Successfully", {
              type: "success",
              autoClose: 2000,
            });
          } else {
            toast("Users Deleted Successfully", {
              type: "success",
              autoClose: 2000,
            });
          }
        },
        onError: (err: any) => {
          toast(err.message, { type: "error", autoClose: 2000 });
        },
      });
    }
  };
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="w-6 h-6 text-primary"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Warning !
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete this Users from company?
                      Once you delete the users will not be able to access
                      company.
                    </p>
                    <p className="text-sm text-gray-500">
                      Please enter the email to delete : {userDetails[0]?.email}
                    </p>
                  </div>
                  <div className="flex pt-2">
                    <label htmlFor="fullName" className="text-lg me-2">
                      Email :
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      id="fullName"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsError(false);
                      }}
                      className="block w-auto border-0 rounded-md text-grey-900 placeholder:text-gray-400 ring-gray-300 ring-1 ring-inset"
                    />
                  </div>
                  {isError && (
                    <div className="pt-1 text-red-600 ps-16">
                      Incorrect Email
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
              <PrimaryCTAButton
                type="button"
                onClick={handleDelete}
                disabled={isError}
                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Delete
              </PrimaryCTAButton>
              <SecondaryCTAButton
                type="button"
                data-autofocus
                onClick={onClose}
                className="inline-flex justify-center w-auto px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </SecondaryCTAButton>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export const StatusRenderer = (data: any) => {
  const status = data.data.onboardingStatus.replaceAll("_", " ").toLowerCase();
  const date = new Date(data.data.onPlatformSince);
  const [isNewCompany, setIsNewCompany] = useState(false);
  useEffect(() => {
    const days = Math.round(
      (new Date().getTime() - date.getTime()) / (1000 * 3600 * 24),
    );
    if (days < 30) {
      setIsNewCompany(true);
    }
  }, []);
  return (
    <div className="capitalize hover:text-primary">
      {status}
      {isNewCompany ? (
        <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-orange-500 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/10 ms-2">
          New
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
