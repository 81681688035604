import React from "react";

interface ViewUsersCellRendererProps {
  onClick: () => void;
  // setIsPopupOpen: (value: boolean) => void;
  // setUserList: (value: string[]) => void;
  value: string[];
}

const ViewUsersCellRenderer: React.FC<ViewUsersCellRendererProps> = ({
  value,
  onClick,
}) => {
  return (
    <div>
      <ClickableCellRenderer
        value={"view users"}
        users={value}
        onClick={onClick}
        // setIsPopupOpen={setIsPopupOpen}
        // setUserList={setUserList}
      />
    </div>
  );
};
export const ViewDetailsCellRenderer: React.FC<ViewUsersCellRendererProps> = ({
  value,
  onClick,
}) => {
  return (
    <div>
      <ClickableCellRenderer
        value={"view Details"}
        users={value}
        onClick={onClick}
        // setIsPopupOpen={setIsPopupOpen}
        // setUserList={setUserList}
      />
    </div>
  );
};

interface ClickableCellRendererProps {
  value: string;
  onClick: () => void;
  // setIsPopupOpen: (value: boolean) => void;
  // setUserList: (value: string[]) => void;
  users: string[];
}

const ClickableCellRenderer: React.FC<ClickableCellRendererProps> = ({
  value,
  // setIsPopupOpen,
  // setUserList,
  users,
}) => {
  const handleButtonClick = () => {
    // setIsPopupOpen(true);
    // setUserList(users);
  };
  return (
    <div>
      <span
        className="text-blue-500 hover:underline cursor-pointer"
        onClick={handleButtonClick}
      >
        {value}
      </span>
    </div>
  );
};

export default ViewUsersCellRenderer;
