import { downloadS3File } from "../utils/download";

const DownloadButton = (data: any) => {
  const value = data.value.split("/");
  const length = value.length;
  return (
    <>
      {value[length - 1] ? (
        <div className="w-full flex flex-row items-center">
          <div
            onClick={() => downloadS3File(data.value)}
            className="flex-1 overflow-clip underline text-blue-700 cursor-pointer"
          >
            download
          </div>
        </div>
      ) : (
        <div>N/A</div>
      )}
    </>
  );
};

export default DownloadButton;
