import moment from "moment";

export class DateUtil {
  public static convertToDate(dateStr: string, format?: string): Date {
    if (format) {
      return moment(dateStr, format).toDate();
    }
    return moment(dateStr).toDate();
  }

  public static formatDate(date: Date, format: string): string {
    let result = "";
    result = this.formatDateToStartOf(date, format, "day");
    return result;
  }

  public static formatDateToStartOf(
    date: Date,
    format: string,
    startOf: any,
  ): string {
    let result = "";
    result = moment(date).startOf(startOf).format(format);
    return result;
  }

  public static formatDateToEndOf(
    date: Date,
    format: string,
    endOf: any,
  ): string {
    let result = "";
    result = moment(date).endOf(endOf).endOf("day").format(format);
    return result;
  }

  public static subtractDate(date: Date, days: number, type: any) {
    const result = moment(date).subtract(days, type);
    return result;
  }

  public static addDate(date: Date, num: number, type: any) {
    const result = moment(date).add(num, type);
    return result;
  }

  public static dateDiff(date1: Date, date2: Date, diffIn: any) {
    return moment(date1).diff(date2, diffIn);
  }

  public static getNameOfLastMonth(): string {
    return moment().subtract(1, "month").startOf("month").format("MMMM");
  }

  public static getCurrentYear(): number {
    return moment().year();
  }
}
