import { useMutation, useQuery } from "react-query";
import {
  esopAdminDetails,
  esopDeleteUser,
  getEsopCompanyStats,
} from "../../api/esopStats";

export function useGetEsopCompanyStats() {
  return useQuery({
    queryKey: "getesopcompanystats",
    queryFn: getEsopCompanyStats,
  });
}
export function useAdminUserDetails() {
  return useMutation({
    mutationKey: "esopAdminDetails",
    mutationFn: esopAdminDetails,
  });
}
export function useDeleteUser() {
  return useMutation({
    mutationKey: "esopDeleteUser",
    mutationFn: esopDeleteUser,
  });
}
