import { useState } from "react";
import { Select } from "../../components/inputComponent";
import ExcelOnboarding from "./s3Upload/ExcelUpload";
import FinancialUploadPage from "./financialUpload/FinancialUploadPage";

function UploadWrapper() {
  const [showS3Upload, setShowS3Upload] = useState(true);
  const [showFinancialUpload, setShowFinancialUpload] = useState(false);
  return (
    <>
      <div className="mt-4 flex justify-end">
        <Select
          className="w-auto max-w-44"
          defaultValue={"S3 Upload"}
          onChange={(e) => {
            if (e.target.value === "S3 Upload") {
              setShowS3Upload(true);
              setShowFinancialUpload(false);
            } else if (e.target.value === "Financials Upload") {
              setShowS3Upload(false);
              setShowFinancialUpload(true);
            }
          }}
          options={["S3 Upload", "Financials Upload"]}
          multiple={false}
        />
      </div>
      <div>
        {showS3Upload && <ExcelOnboarding />}
        {showFinancialUpload && <FinancialUploadPage />}
      </div>
    </>
  );
}

export default UploadWrapper;
