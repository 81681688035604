import { ApprovalStatus } from "../types/onBoardingModel";

export const getReviewStatus = (status: string) => {
  if (status === ApprovalStatus.Publish) {
    return "Reviewed";
  } else if (status === ApprovalStatus.Pending) {
    return "Pending";
  } else if (status === ApprovalStatus.Reviewed) {
    return "Reviewed";
  } else if (status === ApprovalStatus.Rejected) {
    return "Pending";
  } else {
    return "Pending";
  }
};

export const getFinalStatus = (
  reviewerStatus: string,
  approverStatus: string,
) => {
  if (approverStatus === ApprovalStatus.Publish) {
    return "Approved";
  } else if (approverStatus === ApprovalStatus.Rejected) {
    return "Rejected";
  } else if (reviewerStatus === ApprovalStatus.Reviewed) {
    return "Reviewed";
  } else {
    return "Pending";
  }
};

export const getApproverStatus = (status: string) => {
  if (status === ApprovalStatus.Reviewed) {
    return "Pending";
  } else if (status === ApprovalStatus.Pending) {
    return "Pending";
  } else if (status === ApprovalStatus.Publish) {
    return "Approved";
  } else if (status === ApprovalStatus.Rejected) {
    return "Rejected";
  } else {
    return "Pending";
  }
};

export type CaptableCompany = {
  id: string;
  cin?: string;
  name?: string;
  isPrivate?: boolean;
  noOfShareholders?: number;
  noOfRounds?: number;
  onPlatformSince?: string;
  dba?: string;
  noOfCaptables?: number;
  companyAccessData?: CompanyAccessData[];
  stage?: string;
};

export type CompanyAccessData = {
  email?: string;
  createdAt?: string;
  fullName?: string;
  role?: string;
};
export type TableData = {
  createdAt?: string;
  companyName?: string;
  fullName?: string;
  email?: string;
  role?: string;
};
