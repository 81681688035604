/* eslint-disable prettier/prettier */
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import QuickSearch from "../../components/QuickSearch";
import {
  useCheckOrderStatus,
  useGetAllCretaedMCAOrder,
} from "../../queries/onboarding";
import { CreateDownloadOrderType } from "../../types/onBoardingModel";

const MCAOrders: React.FC = () => {
  const navigate = useNavigate();
  const { data: _allmcaorders, isFetching: isOrdersfetched } =
    useGetAllCretaedMCAOrder();
  const { mutate: refetchOrderStatus } = useCheckOrderStatus();
  const gridApi = useRef<AgGridReact>(null);
  const [searchResultCompanies, setSearchResultCompanies] = useState(0);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const allMcaOrders = _allmcaorders as CreateDownloadOrderType[];
  const rowsData = useMemo(
    () =>
      allMcaOrders?.map((mcaorder: CreateDownloadOrderType) => ({
        cin: mcaorder.cin,
        orderStatus: mcaorder.status,
        orderDate: mcaorder.orderDate ? mcaorder.orderDate : "-",
        companyName: mcaorder.name ? mcaorder.name : "-",
        documentType: mcaorder.documentType,
      })),
    [_allmcaorders],
  );
  useEffect(() => {
    setSearchResultCompanies(
      gridApi?.current?.api?.getDisplayedRowCount() || 0,
    );
  }, [gridApi?.current?.api]);

  const components = useMemo(
    () => ({
      companyName: MCAOrders,
    }),
    [],
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "Company CIN",
      field: "cin",
      cellRenderer: "cin",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Company Name",
      field: "companyName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "MCA Order Date",
      field: "orderDate",
    },
    {
      headerName: "MCA Order Status",
      field: "orderStatus",
    },
    {
      headerName: "Document Type",
      field: "documentType",
    },
  ];
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );

  function refreshOrders() {
    refetchOrderStatus();
    toast("Refresh request has been made", {
      autoClose: 2000,
      type: "success",
    });
  }
  return (
    <>
      <div className="flex items-center justify-between py-5">
        <div>
          <h1 className="text-2xl font-medium">MCA Orders</h1>
          <p className="pb-2 text-sm font-medium text-gray-500">
            {searchResultCompanies} companies
          </p>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div
            className="p-2 text-white rounded-sm cursor-pointer bg-orange-501 h-9"
            onClick={() => navigate("/createOrder")}
          >
            Create MCA Order
          </div>
          <div
            className="p-2 text-white rounded-sm cursor-pointer bg-orange-501 h-9"
            onClick={() => refreshOrders()}
          >
            Refresh Order
          </div>
          <div>
            <QuickSearch
              placeholder="Search"
              onChange={(e) => {
                gridApi.current!.api.setGridOption(
                  "quickFilterText",
                  e.target.value,
                );
                setSearchResultCompanies(
                  gridApi.current?.api.getDisplayedRowCount() || 0,
                );
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`${isDarkTheme ? "ag-theme-quartz-dark" : "ag-theme-quartz"} h-[calc(100vh-22vh)]`}
      >
        <AgGridReact
          ref={gridApi}
          components={components}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowsData}
          suppressRowTransform={true}
          pagination={true}
          animateRows={true}
        />
      </div>
    </>
  );
};

export default MCAOrders;
