import { QueryFunctionContext } from "react-query";
import onBoardingApi from "./onBoardingApi";

export async function getEsopCompanyStats(
  context?: QueryFunctionContext,
): Promise<any> {
  return onBoardingApi
    .get(`adminStats/esopStats`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopAdminDetails(companyId: string): Promise<void> {
  return onBoardingApi
    .get(`adminStats/esopAdminDetails?companyId=${companyId}`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopDeleteUser(data: any): Promise<void> {
  return onBoardingApi
    .delete(`adminStats/esopDeleteUser`, { data })
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
