import { useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Select } from "../../../components/inputComponent";
import { useGetGlobalCompaniesDetails } from "../../../queries/onboarding";
import { getCaptableToken } from "../s3Upload/getToken";
import { PrimaryCTAButton } from "../../../components/CTAButtonComponents";

export type GlobalCompanySearchType = {
  name: string;
  id: string;
  valuation: number;
  companyCurrency: string;
  jurisdiction: string;
  valuationDate: string;
  dba: string;
  cin: string;
};
type Options = {
  label: string;
  value: string;
};
function FinancialUploadPage() {
  const { data } = useGetGlobalCompaniesDetails();
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>();
  const [financialType, setFinancialType] = useState<string>("");
  const [timeline, setTimeline] = useState<string>("");
  const [monthOrQtrField, setMonthOrQtrField] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const financialTypeList: Options[] = [
    { label: "StandAlone", value: "StandAlone" },
    { label: "Consolidated", value: "Consolidated" },
  ];
  const yearPeriodOptions: Options[] = [
    { label: "Year", value: "Year" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
  ];
  const months: Options[] = [
    { label: "Jan", value: "1" },
    { label: "Feb", value: "2" },
    { label: "Mar", value: "3" },
    { label: "Apr", value: "4" },
    { label: "May", value: "5" },
    { label: "Jun", value: "6" },
    { label: "Jul", value: "7" },
    { label: "Aug", value: "8" },
    { label: "Sep", value: "9" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ];
  const quarters: Options[] = [
    { label: "Q1", value: "1" },
    { label: "Q2", value: "2" },
    { label: "Q3", value: "3" },
    { label: "Q4", value: "4" },
  ];
  const years: Options[] = [
    { label: "2014-2015", value: "2015" },
    { label: "2015-2016", value: "2016" },
    { label: "2016-2017", value: "2017" },
    { label: "2017-2018", value: "2018" },
    { label: "2018-2019", value: "2019" },
    { label: "2019-2020", value: "2020" },
    { label: "2020-2021", value: "2021" },
    { label: "2021-2022", value: "2022" },
    { label: "2022-2023", value: "2023" },
    { label: "2023-2024", value: "2024" },
  ];
  const handleSubmit = async () => {
    if (
      financialType === "" ||
      timeline === "" ||
      year === "" ||
      (timeline !== "Year" && monthOrQtrField === "")
    ) {
      toast("Please Fill all details", {
        type: "error",
        autoClose: 2000,
      });
    } else {
      const accessToken = (await getCaptableToken()) ?? "";
      const url =
        process.env.REACT_APP_CAPTABLE_URL ||
        "https://captable-api.hissa.com/v1/";
      const formData = new FormData();
      formData.append("file", uploadFile);
      setLoading(true);
      const financialsUpload = axios
        .post(
          `${url}finance/postData?year=${year}&timelineField=${timeline}&monthOrQtrField=${monthOrQtrField}&companyId=${companyId}&financialType=${financialType}`,
          formData,
          {
            headers: {
              Accesstoken: accessToken || "",
            },
          },
        )
        .then((data) => {
          setLoading(false);
          toast("Uploaded successfully", {
            type: "success",
            autoClose: 2000,
          });
        })
        .catch((err) => {
          setLoading(false);
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        });
    }
  };
  const companyOptions: Options[] = useMemo(
    () =>
      data?.map((company) => ({
        label: company.name,
        value: company.id,
      })) || [],
    [data],
  );
  return (
    <div className="h-[calc(100vh-20vh)] py-5 text-center">
      <div className="text-3xl font-semibold">
        Upload Company Financial Data
      </div>
      <div className="flex justify-around pt-5">
        <div className="flex w-4/12">
          <div className="text-lg w-80">Select Company :</div>
          <Select
            options={companyOptions}
            textGetter={(o) => o.label}
            valueGetter={(o) => o.value}
            onChange={(e) => {
              if (e.target.value) {
                setCompanyId(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-4/12">
          <div className="text-lg w-80">Select Financial Type :</div>
          <Select
            options={financialTypeList}
            textGetter={(o) => o.label}
            valueGetter={(o) => o.value}
            onChange={(e) => {
              if (e.target.value) {
                setFinancialType(e.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className="flex justify-center pt-5">
        <div className="text-lg w-40">Select Timeline :</div>
        <div className="w-80 ms-4">
          <Select
            options={yearPeriodOptions}
            textGetter={(o) => o.label}
            valueGetter={(o) => o.value}
            onChange={(e) => {
              if (e.target.value) {
                setTimeline(e.target.value);
              }
            }}
          />
        </div>
        {timeline === "Year" && (
          <div className="w-80 ms-4">
            <Select
              options={years}
              textGetter={(o) => o.label}
              valueGetter={(o) => o.value}
              onChange={(e) => {
                if (e.target.value) {
                  setYear(e.target.value);
                }
              }}
            />
          </div>
        )}
        {timeline === "Monthly" && (
          <>
            <div className="w-80 ms-4">
              <Select
                options={months}
                textGetter={(o) => o.label}
                valueGetter={(o) => o.value}
                onChange={(e) => {
                  if (e.target.value) {
                    setMonthOrQtrField(e.target.value);
                  }
                }}
              />
            </div>
            <div className="w-80 ms-4">
              <Select
                options={years}
                textGetter={(o) => o.label}
                valueGetter={(o) => o.value}
                onChange={(e) => {
                  if (e.target.value) {
                    setYear(e.target.value);
                  }
                }}
              />
            </div>
          </>
        )}
        {timeline === "Quarterly" && (
          <>
            <div className="w-80 ms-4">
              <Select
                options={quarters}
                textGetter={(o) => o.label}
                valueGetter={(o) => o.value}
                onChange={(e) => {
                  if (e.target.value) {
                    setMonthOrQtrField(e.target.value);
                  }
                }}
              />
            </div>
            <div className="w-80 ms-4">
              <Select
                options={years}
                textGetter={(o) => o.label}
                valueGetter={(o) => o.value}
                onChange={(e) => {
                  if (e.target.value) {
                    setYear(e.target.value);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-center">
          <div className="text-lg py-3">Upload File :</div>
          <input
            type="file"
            accept={
              "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            className="ms-4 form-input leading-4 rounded-md border bg-transparent md:py-2 py-1 pl-2 text-[#4b5675] border-inputBorder focus:border-inputBorderFocus placeholder:text-gray-400 focus:duration-800 sm:text-sm text-xs1 sm:leading-6 mt-1.5"
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e: any) => {
              setUploadFile(e.target?.files[0]);
            }}
          />
        </div>
        <PrimaryCTAButton
          className="mt-5"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </PrimaryCTAButton>
      </div>
    </div>
  );
}

export default FinancialUploadPage;
