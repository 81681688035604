import React from "react";
import { Icon } from "@iconify/react";
import { IconSize } from "./CTAButtonComponents";
import { HStack } from "./utils";

export default function QuickSearch(
  props: React.InputHTMLAttributes<HTMLInputElement>,
) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `${_className}`;

  return (
    <HStack className="items-center h-9 p-2 rounded w-full bg-transparent text-slate-dark border border-inputBorderFocus">
      <Icon icon="fe:search" width="24" className="mr-2 " />
      <input
        type="text"
        name="search"
        id="search"
        {...rest}
        className={`w-48 font-medium border-0 outline-none text-xs2 bg-inherit ${className}`}
        placeholder={placeholder}
        onWheel={(e) => e.currentTarget.blur()}
        autoComplete="off"
      />
    </HStack>
  );
}

export function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `${_className} form-input `;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}
