import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0 * 30, // data considered stale after 6 hours
      cacheTime: 0 * 30, // data will be in cache for 6 hours
      // but usage of cache will depend on stale time
    },
  },
});
