import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import QuickSearch from "../../../components/QuickSearch";
import { useGetEsopCompanyStats } from "../../../queries/companyStats/esopStats";
import { formatDisplayDate } from "../../../utils/formatDateDisplay";
import { CompanyDetailPage, StatusRenderer } from "./EsopAgComponents";

export type EsopDetails = {
  companyId: string;
  companyName: string;
  dbaName: string;
  onPlatformSince: Date;
  cin: string;
  companyPoolSize: number;
  totalActivePlanPoolSize: number;
  totalNumberOfPlans: number;
  totalNumberOfEmployees: number;
  totalNumberOfResignedEmployees: number;
  numberOfEmployeesGivenAccess: number;
  totalCashedout: number;
  totalLapsed: number;
  totalStockExercise: number;
  totalSurrendered: number;
  totalVested: number;
  totalGranted: number;
  totalSold: number;
  onboardingStatus: string;
  companyStatus: string;
};

function EsopStats() {
  const gridApi = useRef<AgGridReact>(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [searchResultCompanies, setSearchResultCompanies] = useState(0);
  const { data, refetch } = useGetEsopCompanyStats();
  // const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  // if (darkThemeMq.matches) {
  //   // Theme set to dark.
  // } else {
  //   // Theme set to light.
  // }
  // window
  //   .matchMedia("(prefers-color-scheme: dark)")
  //   .addEventListener("change", (e) => {
  //     setIsDarkTheme(e.matches);
  //     console.log(e);
  //   });

  useEffect(() => {
    setSearchResultCompanies(
      gridApi?.current?.api?.getDisplayedRowCount() || 0,
    );
  }, [gridApi?.current?.api]);
  const components = useMemo(
    () => ({
      companyName: CompanyDetailPage,
      onboarding_status: StatusRenderer,
    }),
    [],
  );
  const rowsData = useMemo(
    () =>
      data?.map((i: EsopDetails) => ({
        ...i,
        companyId: i.companyId,
        companyName: i.companyName,
        dbaName: i.dbaName,
        onPlatformSince: formatDisplayDate(i.onPlatformSince),
        cin: i.cin,
        companyPoolSize: Intl.NumberFormat("en-IN").format(i.companyPoolSize),
        totalActivePlanPoolSize: Intl.NumberFormat("en-IN").format(
          i.totalActivePlanPoolSize,
        ),
        totalNumberOfPlans: i.totalNumberOfPlans,
        totalNumberOfEmployees: i.totalNumberOfEmployees,
        numberOfEmployeesGivenAccess: i.numberOfEmployeesGivenAccess,
        totalNumberOfResignedEmployees: i.totalNumberOfResignedEmployees,
        totalCashedout: Intl.NumberFormat("en-IN").format(i.totalCashedout),
        totalLapsed: Intl.NumberFormat("en-IN").format(i.totalLapsed),
        totalStockExercise: Intl.NumberFormat("en-IN").format(
          i.totalStockExercise,
        ),
        totalSurrendered: Intl.NumberFormat("en-IN").format(i.totalSurrendered),
        totalVested: Intl.NumberFormat("en-IN").format(i.totalVested),
        totalGranted: Intl.NumberFormat("en-IN").format(i.totalGranted),
        totalSold: Intl.NumberFormat("en-IN").format(i.totalSold),
        onboarding_status: i.onboardingStatus
          .replaceAll("_", " ")
          .toLowerCase(),
        company_status: i.companyStatus.replaceAll("_", " ").toLowerCase(),
      })),
    [data],
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "Company Name",
      field: "companyName",
      cellRenderer: "companyName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "DBA Name",
      field: "dbaName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Onboarding Status",
      field: "onboarding_status",
      cellRenderer: "onboarding_status",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Company Status",
      field: "company_status",
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
    {
      headerName: "On Platform Since",
      field: "onPlatformSince",
    },
    {
      headerName: "CIN",
      field: "cin",
    },
    {
      headerName: "Company Pool Size",
      field: "companyPoolSize",
      cellStyle: { display: "flex", justifyContent: "center" },
    },
    {
      headerName: "Total Active Plan Pool Size",
      field: "totalActivePlanPoolSize",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      headerName: "Total Number of Plans",
      field: "totalNumberOfPlans",
      cellStyle: { display: "flex ", justifyContent: "center" },
    },
    {
      headerName: "Total Number of Employees",
      field: "totalNumberOfEmployees",
      cellStyle: { display: "flex ", justifyContent: "center" },
    },
  ];
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );
  return (
    <>
      <div className="flex justify-between pb-5">
        <div>
          <h1 className="text-2xl font-medium">Esop Companies</h1>
          <p className="pb-2 text-sm font-medium text-gray-500">
            {searchResultCompanies} companies
          </p>
        </div>
        <div>
          <QuickSearch
            placeholder="Search"
            onChange={(e) => {
              gridApi.current!.api.setGridOption(
                "quickFilterText",
                e.target.value,
              );
              setSearchResultCompanies(
                gridApi.current?.api.getDisplayedRowCount() || 0,
              );
            }}
          />
        </div>
      </div>
      <div
        className={`${isDarkTheme ? "ag-theme-quartz-dark" : "ag-theme-quartz"} h-[calc(100vh-22vh)]`}
      >
        <AgGridReact
          ref={gridApi}
          components={components}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowsData}
          suppressRowTransform={true}
          pagination={true}
          animateRows={true}
        />
      </div>
    </>
  );
}

export default EsopStats;
