export enum DocumentType {
  ListOfShareHolders = "ListOfShareHolders",
  Pas3 = "Pas3",
  stage3Sheet = "stage3Sheet",
  finalStage3Sheet = "finalStage3Sheet",
}

export enum ApprovalStatus {
  Publish = "Approved",
  Reviewed = "Reviewed",
  Pending = "Pending",
  Rejected = "Rejected",
}

export type FileType = {
  name: string;
  size: number;
  type: string;
};

export type CompanyDocument = {
  id: string;
  companyId: string;
  documentDate: string;
  documentUrl: string;
  documentType: DocumentType;
  asOfDate: string;
  createdAt: string;
  updatedAt: string;
  confidence: number;
};

export type GridTableModelData = {
  approverId: string;
  assignedTo: string;
  createdAt: string;
  note: string;
  id: string;
  name: string;
  onboardingState: ApprovalStatus;
  onboarding_uri: string;
  taskCompany: CompanyModelData[];
  updatedAt: string;
};

export type CompanyModelData = {
  cin: string;
  createdAt: string;
  id: string;
  name: string;
  taskId: string;
  updatedAt: string;
  CompanyDocument: CompanyDocument[];
};

export type ApproverModel = {
  id: string;
  name: string;
  email: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
};

export type ReviewerModel = {
  id: string;
  name: string;
  email: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
};

export type EntryDataModel = {
  taskId: string;
  companyName: string;
  date: string;
  documentUri: string;
  cin: string;
  latestMgt7: string;
  pas3BeforeMgt7Date: string;
  excelGeneratedBySystem: string;
  latestMgt7Id: string;
  pas3BeforeMgt7DateId: string;
  latestMgt7Date: string;
  pas3BeforeMgt7DateIdDate: string;
  excelGeneratedBySystemId: string;
  finalExcel: string;
  finalExcelId: string;
  reviewer: string;
  reviewerStatus: string;
  approver: string;
  approverStatus: string;
  deadline: string;
  lastModified: string;
  note: string;
  publish: string;
  confidence: number;
};

export type CreateDownloadOrderType = {
  cin: string;
  status: string;
  orderDate: string;
  name: string;
  s3Url: string;
  documentType: string;
};

export type CinValidation = {
  cin: string;
  isDuplicate: string;
  documentType?: string;
};
