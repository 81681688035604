import { useMutation, useQuery } from "react-query";
import {
  addApprover,
  addCompanyTask,
  addFinalExcel,
  addReviewer,
  addTaskStatus,
  checkOrderStatus,
  cinValidation,
  createMCAOrder,
  deleteFinalExcel,
  getAllApprover,
  getAllCreatedMCAOrder,
  getAllReviewer,
  getAllTask,
  getGlobalCompaniesDetails,
  uploadCompanyExcel,
} from "../api/onboarding";
import { queryClient } from "./client";

export function useUploadCompanyExcel() {
  return useMutation({
    mutationKey: "uploadCompanyExcel",
    mutationFn: uploadCompanyExcel,
  });
}

export function useAddCompanyTask() {
  return useMutation({
    mutationKey: "addCompanyTask",
    mutationFn: addCompanyTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useAddApprover() {
  return useMutation({
    mutationKey: "addApprover",
    mutationFn: addApprover,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useDeleteFinalExcel() {
  return useMutation({
    mutationKey: "deleteFinalExcel",
    mutationFn: deleteFinalExcel,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useAddReviewer() {
  return useMutation({
    mutationKey: "addReviewer",
    mutationFn: addReviewer,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useAddTaskStatus() {
  return useMutation({
    mutationKey: "addTaskStatus",
    mutationFn: addTaskStatus,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useAddFinalExcel() {
  return useMutation({
    mutationKey: "addFinalExcel",
    mutationFn: addFinalExcel,
    onSuccess: () => {
      queryClient.invalidateQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}

export function useGetAllTask() {
  return useQuery({
    queryKey: "getalltask",
    queryFn: getAllTask,
  });
}
export function useGetAllApprover() {
  return useQuery({
    queryKey: ["getAllApprover"],
    queryFn: getAllApprover,
  });
}
export function useGetAllReviewer() {
  return useQuery({
    queryKey: ["getAllReviewer"],
    queryFn: getAllReviewer,
  });
}

export function useGetGlobalCompaniesDetails() {
  return useQuery({
    queryKey: "getGlobalCompaniesDetails",
    queryFn: getGlobalCompaniesDetails,
  });
}

export function useCinValidation() {
  return useMutation({
    mutationFn: cinValidation,
    mutationKey: "cinValidation",
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useCreateMCAOrder() {
  return useMutation({
    mutationFn: createMCAOrder,
    mutationKey: "createMCAOrder",
    onSuccess: () => {
      queryClient.invalidateQueries("getCreatedMCAOrder");
      queryClient.refetchQueries("getCreatedMCAOrder");
    },
    onError: () => {},
  });
}

export function useGetAllCretaedMCAOrder() {
  return useQuery({
    queryFn: getAllCreatedMCAOrder,
    queryKey: "getAllCreatedMCAOrder",
  });
}

export function useCheckOrderStatus() {
  return useMutation({
    mutationFn: checkOrderStatus,
    mutationKey: "checkOrderStatus",
    onSuccess: () => {
      queryClient.invalidateQueries("getCreatedMCAOrder");
      queryClient.refetchQueries("getCreatedMCAOrder");
    },
  });
}
