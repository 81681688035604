import { Fragment, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import {
  GlobalAccount,
  InvestorStatus,
  UpdateCompanyInvestorStateInput,
  UpdateGlobalInvestorStateInput,
} from "../../types/globalInvestorsModel";
import { queryClient } from "../../queries/client";
import { CTADropdown } from "../../components/Dropdown";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../components/CTAButtonComponents";
import { Box } from "../../components/utils";
import {
  useLinkGlobalInvestors,
  useLinkCompanyInvestors,
} from "../../queries/companyStats/captableStats";

export function InvestorNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left ">
      <div className="flex">
        <div className=" cursor-pointer">
          <div>
            {data.data.orgHierarchy?.length === 1 && (
              <>
                <p className="text-left font-semibold pt-3 text-xs2 text-[#464E5F]">
                  {data.value || ""}
                </p>
                {/* <p className="text-xs text-gray-400 font-medium">
                  {data.data.globalInvestorId}
                </p> */}
              </>
            )}
          </div>
        </div>
      </div>
    </td>
  );
}

export function EmailRender(data: any) {
  return (
    <div>
      <p className=" "> {data.data.email ? data.data.email : "-"}</p>
    </div>
  );
}

export function CompanyNameRender(data: any) {
  return (
    <div>
      <p className=" font-semibold"> {data.data.companyName}</p>
    </div>
  );
}

export function StatusRender(data: any) {
  return (
    <div>
      {data.data.status === "approved" && (
        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-green-900 dark:text-green-300 border border-green-400">
          Approved
        </span>
      )}
      {data.data.status === "rejected" && (
        <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300 border border-red-400">
          Rejected
        </span>
      )}
      {data.data.status === "pending" && (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-yellow-900 dark:text-yellow-300 border border-yellow-300">
          Pending
        </span>
      )}
    </div>
  );
}

export function ActionRender(data: any) {
  const { mutate: LinkInvestor } = useLinkGlobalInvestors();
  const { mutate: LinkCompany } = useLinkCompanyInvestors();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const handleAction = (
    data: any,
    status: InvestorStatus,
    isCompany: boolean,
  ) => {
    if (isCompany) {
      const companyInputData: UpdateCompanyInvestorStateInput = {
        companyInvestorId: data.companyInvestorId,
        globalInvestorId: data.globalInvestorId,
        companyId: data.companyId,
        status,
      };

      LinkCompany(companyInputData, {
        onSuccess: (res) => {
          queryClient.invalidateQueries("getGlobalInvestors");
          toast(
            `${status === "approved" ? "Approved" : "Rejected"} Successfully `,
            {
              type: "success",
              autoClose: 2000,
            },
          );
        },
        onError: (err: any) => {
          toast(err?.response?.data?.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      const inputData: UpdateGlobalInvestorStateInput = {
        globalAccountId: data.globalAccountId,
        globalInvestorId: data.globalInvestorId,
        status,
        isAccount: false,
        isInvestor: true,
      };

      LinkInvestor(inputData, {
        onSuccess: (res) => {
          setShowConfirm(false);
          queryClient.invalidateQueries("getGlobalInvestors");
          toast(
            `${status === "approved" ? "Approved" : "Rejected"} Successfully `,
            {
              type: "success",
              autoClose: 2000,
            },
          );
        },
        onError: (err: any) => {
          setShowConfirm(false);
          toast(err?.response?.data?.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };

  function handleModifyAction(
    action: string,
    data: GlobalAccount,
    isCompany: boolean,
  ) {
    if (action === "Approve") {
      handleAction(data, "approved", isCompany);
    }
    if (action === "Reject" && !isCompany) {
      setShowConfirm(true);
    }
    if (action === "Reject" && isCompany) {
      handleAction(data, "rejected", isCompany);
    }
  }

  return (
    <>
      <Transition.Root show={showConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowConfirm(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <span className="font-bold">Note : </span>
                  <span className="indicator-progress text-sm font-semibold">
                    Rejecting Global Investor Request will result in removed all
                    pending company investor linking requests.
                  </span>
                  <div className="pt-8 flex justify-between sm:flex sm:flex-row-reverse">
                    <PrimaryCTAButton
                      className={`sm:ml-3 cursor-pointer`}
                      onClick={() => handleAction(data.data, "rejected", false)}
                    >
                      Confirm
                    </PrimaryCTAButton>
                    <SecondaryCTAButton onClick={() => setShowConfirm(false)}>
                      Cancel
                    </SecondaryCTAButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {data.data.status !== undefined ? (
        // <HStack className="flex justify-center">
        //   {data.data.status === "pending" && (
        //     <div className="px-0">
        //       <PrimaryCTAButton
        //         className="me-2 mb-1 bg-success hover:bg-success hover:border-white"
        //         onClick={() => {
        //           handleAction(data.data, "approved");
        //         }}
        //       >
        //         Approve
        //       </PrimaryCTAButton>

        //       <PrimaryCTAButton
        //         className="me-2 bg-reject hover:bg-reject hover:border-white"
        //         onClick={() => {
        //           handleAction(data.data, "rejected");
        //         }}
        //       >
        //         Reject
        //       </PrimaryCTAButton>
        //     </div>
        //   )}
        // </HStack>
        data.data.status === "pending" && (
          <CTADropdown
            actions={[
              {
                name: "Approve",
                disabled: data.data.status === "approved",
              },
              {
                name: "Reject",
                disabled: data.data.status === "approved",
              },
            ]}
            onAction={(action) => {
              if (data.data.companyId !== undefined) {
                handleModifyAction(action.name, data.data, true);
              }
              if (data.data.companyId === undefined) {
                handleModifyAction(action.name, data.data, false);
              }
            }}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
}
