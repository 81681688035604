import {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  ApprovalStatus,
  ApproverModel,
  EntryDataModel,
  FileType,
  ReviewerModel,
} from "../../types/onBoardingModel";
import { downloadS3File } from "../../utils/download";
import { Select } from "../../components/inputComponent";
import {
  useAddApprover,
  useAddFinalExcel,
  useAddReviewer,
  useAddTaskStatus,
  useDeleteFinalExcel,
} from "../../queries/onboarding";
import {
  getApproverStatus,
  getFinalStatus,
  getReviewStatus,
} from "../../utils/Utility";
import { useAuthStore } from "../../store";
import { queryClient } from "../../queries/client";
import { formatDisplayDate } from "../../utils/formatDateDisplay";

export default function OnboardingPanel({
  value,
  reviewer,
  approver,
  open,
  onSuccess,
  close,
}: {
  value: EntryDataModel;
  reviewer: ReviewerModel[];
  approver: ApproverModel[];
  open: boolean;
  onSuccess: () => void;
  close: () => void;
}) {
  const user = useAuthStore();
  const { mutate: addApprover } = useAddApprover();
  const { mutate: AddReviewer } = useAddReviewer();
  const { mutate: deleteFinalExcel } = useDeleteFinalExcel();
  const { mutate: updateStatus } = useAddTaskStatus();

  const [note, setNote] = useState(value.note);
  const [approverInput, setApproverInput] = useState<string>();
  const [deadline, setDeadline] = useState<string>();
  const [reviewerInput, setReviewerInput] = useState<string>();
  const [approverStatus, setApproverStatus] = useState<string>();
  const [reviewerStatus, setReviewerStatus] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const initialValue = {
    name: "",
    size: 0,
    type: "",
  };

  useEffect(() => {
    setDeadline(value.deadline);
    setNote(value.note ?? "");
    setApproverInput(value.approver ?? "");
    setReviewerInput(value.reviewer ?? "");
    setApproverStatus(value.approverStatus ?? "");
    setReviewerStatus(value.reviewerStatus ?? "");
    setUploadedFile(undefined);
  }, [value]);

  const resetTheState = () => {
    setNote("");
    setApproverInput("");
    setReviewerInput("");
    setApproverStatus("");
    setReviewerStatus("");
    setUploadedFile(undefined);
  };

  const [uploadedFileName, setUploadedFileName] =
    useState<FileType>(initialValue);

  const { mutate: uploadFinalExcel } = useAddFinalExcel();
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setUploadedFile(file);
      setUploadedFileName({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", ",
        )}`,
        { type: "error" },
      );
    }
    fileRef.current.value = "";
  }

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;
  const dateRef = useRef() as MutableRefObject<HTMLInputElement>;

  const onSubmit = async () => {
    const selectedReviewer = reviewer.find((ele) => ele.name === reviewerInput);
    const selectedApprover = approver.find((ele) => ele.name === approverInput);
    const reviewerData = {
      userId: selectedReviewer?.id,
      taskId: value.taskId,
    };

    const approverData = {
      userId: selectedApprover?.id,
      taskId: value.taskId,
    };
    try {
      if (selectedReviewer) AddReviewer(reviewerData);
    } catch (e) {
      //empty black
    }

    try {
      if (selectedApprover) addApprover(approverData);
    } catch (e) {
      //empty block
    }

    try {
      if (approverStatus || reviewerStatus) {
        const taskData = {
          taskId: value.taskId,
          status: getFinalStatus(reviewerStatus ?? "", approverStatus ?? ""),
          note,
        };
        updateStatus(taskData);
      }
    } catch (e) {
      //empty block
    }

    try {
      if (uploadedFile) {
        const formData = new FormData();
        formData.append("taskId", value.taskId ?? "");

        formData.append("userId", selectedReviewer?.id ?? "");

        formData.append("cin", value.cin ?? "");

        formData.append("fileType", "finalStage3Sheet");

        if (uploadedFile) {
          formData.append("file", uploadedFile);
          uploadFinalExcel({ formData });
        }
      }
    } catch (e) {
      //empty block
    }
    resetTheState();
    close();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-128">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => close()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <Icon
                          icon="octicon:x-16"
                          className="text-white h-5 cursor-pointer"
                          height={20}
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <div className="space-y-6">
                      <div className="border-b border-gray-200">
                        <div className="flex items-start justify-between">
                          <div className="gap-4 ">
                            <div className="pb-2">
                              <h2 className="text-base font-semibold leading-6 text-gray-900">
                                <span className="sr-only">Company Name </span>
                                {value.companyName}
                              </h2>
                              <p className="text-sm font-medium text-gray-500">
                                cin : {value.cin}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-row justify-end gap-4">
                            <button
                              type="button"
                              onClick={() => {
                                onSubmit();
                              }}
                              className=" rounded-md bg-secondary px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80"
                            >
                              Save
                            </button>
                            {user.role !== "Intern" &&
                              value.finalExcel &&
                              value.reviewerStatus ===
                                ApprovalStatus.Reviewed &&
                              value.approverStatus !==
                                ApprovalStatus.Publish && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (approverStatus || reviewerStatus) {
                                      const taskData = {
                                        taskId: value.taskId,
                                        status: "Approved",
                                        note,
                                      };
                                      updateStatus(taskData);
                                      close();
                                    }
                                  }}
                                  className=" rounded-md bg-secondary px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary/80"
                                >
                                  Publish
                                </button>
                              )}
                            {user.role !== "Intern" &&
                              value.finalExcel &&
                              value.approverStatus ===
                                ApprovalStatus.Publish && (
                                <button
                                  type="button"
                                  disabled
                                  className=" rounded-md bg-secondary/50 cursor-not-allowed px-2 py-2 text-sm font-semibold text-white shadow-sm"
                                >
                                  Published
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-row justify-start items-center gap-4 -mt-2 mb-4">
                          <h3 className="font-medium text-gray-900">
                            Dead Line
                          </h3>
                          <h3 className="font-medium text-gray-900">
                            {formatDisplayDate(deadline)}
                          </h3>
                          <h3
                            onClick={() => {
                              dateRef.current.click();
                            }}
                            className="text-blue-600 font-medium cursor-pointer"
                          >
                            Change
                          </h3>

                          <input
                            ref={dateRef}
                            type="date"
                            name="date"
                            hidden
                            id="date"
                            value={deadline}
                            onChange={(e) => {
                              setDeadline(e.target.value);
                            }}
                            placeholder=""
                          />
                        </div>
                        <div className="flex justify-between text-sm font-medium">
                          <h3 className="font-medium text-gray-900">
                            All Files
                          </h3>
                          <dd
                            onClick={() => {
                              const data = [
                                value.latestMgt7,
                                value.pas3BeforeMgt7Date,
                                value.excelGeneratedBySystem,
                                value.finalExcel,
                              ];
                              for (let r = 0; r < 4; r++) {
                                downloadS3File(data[r]);
                              }
                            }}
                            className="text-blue-700 underline font-medium cursor-pointer"
                          >
                            DOWNLOAD ALL
                          </dd>
                        </div>

                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <div className="flex justify-between py-1 text-sm font-medium">
                            <div className="flex flex-col">
                              <p className="text-gray-500 flex flex-col gap-0 pb-0 mb-0 leading-6">
                                {" "}
                                List of shareholders
                              </p>
                              <p className="text-gray-500 text-[11px] flex flex-col pt-0 mt-0 leading-4">
                                {value.latestMgt7Date}
                              </p>
                            </div>
                            {value.latestMgt7 === "" ? (
                              <></>
                            ) : (
                              <Icon
                                icon="material-symbols:download"
                                className="text-blue-700 h-5 cursor-pointer"
                                height={20}
                                onClick={() => downloadS3File(value.latestMgt7)}
                              />
                            )}
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <div className="flex flex-col">
                              <p className="text-gray-500 flex flex-col gap-0 pb-0 mb-0 leading-6">
                                PAS 3
                              </p>
                              <p className="text-gray-500 text-[11px] flex flex-col pt-0 mt-0 leading-4">
                                {value.pas3BeforeMgt7DateIdDate}
                              </p>
                            </div>
                            <dt className="text-gray-500"></dt>
                            {value.pas3BeforeMgt7Date === "" ? (
                              <></>
                            ) : (
                              <Icon
                                icon="material-symbols:download"
                                className="text-blue-700 h-5 cursor-pointer"
                                height={20}
                                onClick={() =>
                                  downloadS3File(value.pas3BeforeMgt7Date)
                                }
                              />
                            )}
                            {/*Diryt commit  <DownloadIcon
                              onClick={() =>
                                downloadS3File(value.pas3BeforeMgt7Date)
                              }
                              className="text-blue-700 h-5 cursor-pointer"
                            /> */}
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">
                              Generated Excel Sheet ({value.confidence}%)
                            </dt>
                            {value.excelGeneratedBySystem === "" ? (
                              <></>
                            ) : (
                              <Icon
                                icon="material-symbols:download"
                                className="text-blue-700 h-5 cursor-pointer"
                                height={20}
                                onClick={() =>
                                  downloadS3File(value.excelGeneratedBySystem)
                                }
                              />
                            )}
                            {/*Diryt commit  <DownloadIcon
                              onClick={() =>
                                downloadS3File(value.excelGeneratedBySystem)
                              }
                              className="text-blue-700 h-5 cursor-pointer"
                            /> */}
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Final Excel Sheet</dt>
                            {value.finalExcel ? (
                              <div className="flex flex-row gap-4">
                                <Icon
                                  icon="material-symbols:download"
                                  className="text-blue-700 h-5 cursor-pointer"
                                  height={20}
                                  onClick={() =>
                                    downloadS3File(value.finalExcel)
                                  }
                                />
                                {value.approverStatus !== "Approved" && (
                                  <Icon
                                    icon="octicon:x-16"
                                    className="cursor-pointer text-red-500 h-5 font-medium"
                                    height={20}
                                    onClick={() => {
                                      deleteFinalExcel(value.finalExcelId);
                                      close();
                                    }}
                                  />
                                )}
                              </div>
                            ) : uploadedFileName.name ? (
                              <a className="text-blue-700 underline cursor-pointer whitespace-nowrap flex flex-row">
                                {uploadedFileName.name}
                                <Icon
                                  icon="octicon:x-16"
                                  className="cursor-pointer text-red-500 h-5 font-medium"
                                  height={20}
                                  onClick={() => {
                                    setUploadedFileName(initialValue);
                                    setUploadedFile(undefined);
                                  }}
                                />
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  fileRef.current.click();
                                }}
                                className="text-blue-700 underline cursor-pointer"
                              >
                                <input
                                  ref={fileRef}
                                  onChange={handleChange}
                                  multiple={false}
                                  type="file"
                                  accept=".xlsx, .xls, .ods"
                                  hidden
                                />
                                Upload File
                              </a>
                            )}
                          </div>
                        </dl>
                      </div>

                      <div className="flex flex-row gap-4">
                        {user.role !== "Intern" && (
                          <div className="flex-1">
                            <h3 className="font-medium text-gray-900 mb-2">
                              Reviewer
                            </h3>

                            <Select
                              options={reviewer.map((ele) => ele.name)}
                              value={reviewerInput}
                              disabled={user.role === "Intern"}
                              onChange={(e) => {
                                setReviewerInput(e.target.value);
                              }}
                            />
                          </div>
                        )}
                        <div className="flex-1">
                          <h3 className="font-medium text-gray-900 mb-2">
                            Reviewer Status
                          </h3>

                          <Select
                            options={["Pending", "Reviewed"]}
                            value={getReviewStatus(reviewerStatus ?? "")}
                            disabled={
                              getApproverStatus(value.approverStatus ?? "") ===
                              "Approved"
                            }
                            onChange={(e) => {
                              setReviewerStatus(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row gap-4">
                        {user.role !== "Legal" && (
                          <div className="flex-1">
                            <h3 className="font-medium text-gray-900 mb-2">
                              Approver
                            </h3>
                            <Select
                              options={approver.map((ele) => ele.name)}
                              value={approverInput}
                              disabled={user.role === "Intern"}
                              onChange={(e) => {
                                setApproverInput(e.target.value);
                              }}
                            />
                          </div>
                        )}
                        <div className="flex-1">
                          {/* <h3 className="font-medium text-gray-900 mb-2">
                            Approver Status
                          </h3>

                          <Select
                            options={["Pending", "Approved", "Rejected"]}
                            value={getApproverStatus(approverStatus ?? "")}
                            disabled={
                              user.role === "Intern" ||
                              getApproverStatus(value.approverStatus ?? "") ===
                                "Approved"
                            }
                            onChange={(e) => {
                              setApproverStatus(e.target.value);
                            }}
                          /> */}
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Additional Note
                        </h3>
                        <div className="mt-2 flex items-center justify-between">
                          <textarea
                            id="project-description"
                            name="project-description"
                            rows={2}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={""}
                            value={note}
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
