import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { PrimaryCTAButton } from "../../../components/CTAButtonComponents";
import ValidationErrorsDialog from "./Validation";
import { useUploadCompanyExcel } from "../../../queries/onboarding";

function ExcelOnboarding() {
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { mutate: uploadCompanyOnboardingExcel } = useUploadCompanyExcel();
  const [success, setSuccess] = useState(false);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: string[];
  }>({
    open: false,
  });
  const displayErrors = (errors: string[]) => {
    setDialog({
      open: true,
      data: errors,
    });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      uploadCompanyOnboardingExcel(
        {
          formData,
        },
        {
          onSuccess: () => {
            toast("Uploaded Successfully!", {
              type: "success",
              autoClose: 2000,
            });
            setSuccess(true);
          },
          onError: (error: any) => {
            setSuccess(false);
            if (
              error.response.data.errors &&
              error.response.data.errors.length > 0
            )
              displayErrors(error.response.data.errors);
            else
              toast(`${error.response.data.errorMessage}`, {
                type: "error",
                autoClose: 2000,
              });
          },
        },
      );
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", ",
        )}`,
        { type: "error" },
      );
    }
    fileRef.current.value = "";
  }

  return (
    <main className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 rounded-lg h-[calc(100vh-15vh)]">
      {success ? (
        <div className="text-center">
          <h1 className=" text-3xl font-bold tracking-tight text-green-600 sm:text-5xl">
            Upload Successfull
          </h1>
          <p className="mt-6 text-base leading-7 text-green-900">
            The Excel sheet you provided has been successfully uploaded to the
            server.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <div>
              <PrimaryCTAButton
                className="bg-secondary"
                onClick={() => {
                  setSuccess(false);
                }}
              >
                Upload Another
              </PrimaryCTAButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h1 className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Hissa S3 Upload
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Please upload the excel sheet to publish the stage 3 company
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <div>
              <input
                ref={fileRef}
                onChange={handleChange}
                multiple={false}
                type="file"
                accept=".xlsx, .xlsm"
                hidden
              />
              <PrimaryCTAButton
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                Upload Excel
              </PrimaryCTAButton>
            </div>
            {/* <Dialog open={dialog.open} maxWidth="lg"> */}
            <ValidationErrorsDialog
              errors={dialog.data || []}
              value={dialog.open}
              onSuccess={() => setDialog({ open: false })}
              close={() => setDialog({ open: false })}
            />
            {/* </Dialog> */}
          </div>
        </div>
      )}
    </main>
  );
}

export default ExcelOnboarding;
