import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { SecondaryCTAButton } from "../../../components/CTAButtonComponents";
import { useGetCompanyDetails } from "../../../queries/companyStats/captableStats";

type Props = {
  isCompanyDetailsPopup: boolean;
  onClose: () => void;
  userList: any;
};
function CompanyDetailsPopup({
  isCompanyDetailsPopup,
  onClose,
  userList,
}: Props) {
  const companyDetails = useGetCompanyDetails(userList.id);

  return (
    <Transition.Root show={isCompanyDetailsPopup} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {companyDetails.isLoading ? (
                <Dialog.Panel>
                  <h1 className="indicator-progress text-lg">
                    Please wait....
                  </h1>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="p-5">
                    <div className="flex">
                      <h1 className="font-bold text-lg">Valuation :</h1>
                      <div className="ms-2 text-lg">
                        {companyDetails?.data?.valuation || "-"}
                      </div>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-lg">Share Price :</h1>
                      <div className="ms-2 text-lg">
                        {companyDetails?.data?.pps || "-"}
                      </div>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-lg">Founder Valuation :</h1>
                      <div className="ms-2 text-lg">
                        {Number(
                          companyDetails?.data?.founderHoldingPercentage,
                        ) * Number(companyDetails?.data?.valuation) || "-"}
                      </div>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-lg">
                        Small Investor Valuation :
                      </h1>
                      <div className="ms-2 text-lg">
                        {Number(
                          companyDetails?.data?.smallInvestorHoldingPercentage,
                        ) * Number(companyDetails?.data?.valuation) || "-"}
                      </div>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-lg">
                        Esop Pool Valuation :
                      </h1>
                      <div className="ms-2 text-lg">
                        {Number(companyDetails?.data?.esopPercentage) *
                          Number(companyDetails?.data?.valuation) || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start py-2">
                    <SecondaryCTAButton onClick={onClose}>
                      Cancel
                    </SecondaryCTAButton>
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CompanyDetailsPopup;
